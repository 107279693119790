import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Contact from "../components/home/Contact"

const Contacto = ({ location }) => (
  <Layout location={location}>
    <SEO title="Contacto" />
    <Contact location={location} />
  </Layout>
)

export default Contacto